import React, { useState } from 'react';

import './pages.scss';
import fio from '../../assets/galery/fio.jpeg';
import fio2 from '../../assets/galery/fio2.jpeg';
import pimentao1 from '../../assets/galery/pimentao1.jpeg';
import pimentao2 from '../../assets/galery/pimentao2.jpeg';
import pimento from '../../assets/galery/pimento.jpeg';
import tripa1 from '../../assets/galery/tripa1.jpeg';
import tripa2 from '../../assets/galery/tripa2.jpeg';
import tripa3 from '../../assets/galery/tripa3.jpeg';



function ProductsPage() {

    const [imageInfo, setImageInfo] = useState(null);
    const images = [fio, fio2, pimentao1, pimentao2, pimento, tripa1, tripa2, tripa3];

    const closeImage = (e) => {
      e.preventDefault();
      setImageInfo(null);
    }
    
    return (
        <div className="page">
          <h1 className='page__title'>Produtos</h1>
          <h2 className='page__sub-title'>Só tripas</h2>
          <section className="page__gallery">
              {
                  images.map((image, index) => {
                    return (
                      <img key={ index } className='page__product--thumb' src={ image } alt={`Só tripas produto ${ index + 1 }`} onClick={ () => setImageInfo(image)}/>
                    )
                  })
              }
          </section>
          {
            imageInfo !== null && (
              <div className="page__expanded" onClick={ closeImage }>
                <div className='page__close' onClick={ closeImage }>X</div>
                <img src={ imageInfo } alt="Imagem aberta só tripas" className='page__open-image'/>
              </div>
            )
          }
        </div>
      )
}

export default ProductsPage
