import React from 'react'

import sotripas from '../../assets/Images/sotripas.png';

function AboutPage() {
  return (
    <div className="page">
      <h1 className='page__title'>Missão e valores</h1>
      <h2 className='page__sub-title'>Só tripas</h2>
      <section className="page__section">
        <p className='page__paragraph'>A só tripas é uma empresa credenciada a nivel europeu, estando certificada pelas autoridades competentes DGAVE- DIREÇÃO GERAL ALIMENTAR E VETERINÁRIA.Dispomos de todos os certificados necessários para o bom funcionamento comercial, respeitando todas as normas e leis europeias para a boa comercialização de todos os produtos alimentares e demais complementos de que dispomos na nossa vasta lista de produtos alimentares.Comercializamos todo o tipo de tripa seca e fresca de origem animal(bovina ou suina), assim como tripa sintética.Em complemento com a tripa dispomos também de pimentão doce, picante e algodão.</p>
      <p className="page__paragraph">Ficamos ao seu dispor para tudo o que achar conveniente.</p>
        <img src={ sotripas } alt="logo só tripas" className='page__bottom-image' />
      </section>
    </div>
  )
}

export default AboutPage
