function Ek() {
    return (
        {
            USER_ID: `user_Qu00rRu23MMccapUsdzYE`, //userID
            TEMPLATE_ID: `template_dxud4vd`,         //templateID
            SERVICE_ID: `service_vsl0on8`
        }
    )
}

export default Ek
