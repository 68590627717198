import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import apiKey from '../../ek';

function ContactsPage() {
  const form = useRef()
  const [showForm, setShowForm] = useState(true);  
  const [fields, setFields] = useState({
    name: '',
    email: '',
    title: '',
    message: ''
  })
  
  const handleChange = (e) => {
    e.preventDefault();
    setFields({...fields, [e.target.name] : e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, form.current , apiKey.USER_ID)
    .then((result) => {
      setShowForm(false);
    }, (error) => {
        console.log(error.text);
    });
  }

  return (
    <div className='page'>
        <h1 className='page__title'>Contactos</h1>
        <h2 className='page__sub-title'>Só tripas</h2>
      <div className="page__contacts">
        <div className="page__address">
          <h3>Morada</h3>
          Rua dr. João Gonçalves <br />
          nº18 R/c A,<br />
          5340-261 Macedo de Cavaleiros <br />
          Email: <a href="mailto:afonso.sotripas@gmail.com">afonso.sotripas@gmail.com</a>
        </div>
        <div className="page__form">
          {showForm && 
          <form ref={ form } type="submit" onSubmit={ handleSubmit }>
            <input type="text" placeholder='Nome' name='name' className='page__input' required value={fields.name} onChange={ handleChange }/>
            <input type="email" placeholder='Email' name='email' className='page__input' required value={fields.email} onChange={ handleChange }/>
            <input type="text" placeholder='Assunto' name='title' className='page__input'required value={fields.title} onChange={ handleChange }/>
            <textarea name="message" id="" cols="30" rows="10" placeholder='Mensagem' className='page__input' value={fields.message} onChange={ handleChange }/>
            <button type='submit'> Enviar mensagem </button>
          </form>
          }
          { !showForm && 
          <div className="page__message">
            A sua mensagem foi enviada com sucesso, seremos breves na resposta…
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ContactsPage
