import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import MainPage from '../Pages/MainPage';
import AboutPage from '../Pages/AboutPage';
import Footer from '../Footer/Footer';
import './Container.scss';
import ProductsPage from '../Pages/ProductsPage';
import ContactsPage from '../Pages/ContactsPage';

function Container() {

    return (
      <Router>
        <div className="so-tripas">
          <Header />
          <Navigation />
            <Routes>
            <Route exact path='/' element={<MainPage/>} />
            <Route exact path='/sobre-nos' element={<AboutPage/>} />
            <Route exact path='/produtos' element={<ProductsPage />} />
            <Route exact path='/contactos' element={<ContactsPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    )
}

export default Container
