import React from 'react';

import tripas from '../../assets/Images/tripa.jpeg';
import './pages.scss';

function MainPage() {
  return (
    <div className="page">
      <div className="page__slider" />
      <h1 className='page__title'>Só Tripas</h1>
      <h2 className='page__sub-title'>Venda de tripas para fumeiro</h2>
      <section className="page__section">
        <p className='page__paragraph'>A empresa SÓ TRIPAS, foi criada com o intuito de responder a necessidade de continuidade e preservação da cultura gastronómica transmontana. Tendo o passado como referência, mas vivendo tempos cada vez mais modernos, houve a necessidade de atualizar os processos que caracterizam a tão apreciada cozinha do Nordeste de Portugal. A empresa interveio nesse processo de atualização, com o compromisso de levar as empresas e particulares, produtos de qualidade que preservam os sabores do passado obedecendo as exigências do presente.</p>
        <p className='page__paragraph'>A empresa SÓ TRIPAS esforça-se por ser um parceiro de negocio que acompanha todas as instituições que nela depositam a sua confiança. Procurando e levando até aos seus clientes, produtos que correspondem as exigências destes, procura contribuir para o crescimento económico da região e a preservação da sua cultura e tradição.</p>
        <p className='page__paragraph'>A empresa pretende continuar o seu caminho de crescimento que tem marcado o seu percurso ao longo do tempo, alargando a sua área de ação sem nunca esquecer os seus clientes e os produtos que a caracterizam e e que fazem parte da sua identidade</p>
        <img src={ tripas } alt="tripa só tripas" className='page__bottom-image'/>
      </section>
    </div>
  )
}

export default MainPage
