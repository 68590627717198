import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Navigation.scss';

function Navigation() {

  const [showMobile, setShowMobile] = useState(false);
  
  return (
    <nav className="navigation">
      <ul className='navigation__full-menu'>
        <li className='navigation__menu-item'>
          <Link className='navigation__menu-link' to='/'>Início</Link>
        </li>
        <li className='navigation__menu-item'>
          <Link className='navigation__menu-link' to='/sobre-nos'>Quem Somos</Link>
        </li>
        <li className='navigation__menu-item'>
          <Link className='navigation__menu-link' to='/produtos'>Produtos</Link>
        </li>
        <li className='navigation__menu-item'>
          <Link className='navigation__menu-link' to='/contactos'>Contactos</Link>
        </li>
      </ul>
      <div className="mobile" onClick={() => setShowMobile(!showMobile)}>
        Menu
      </div>
     { showMobile && 
      <ul className='mobile__menu'>
        <li className='mobile__menu-item'>
          <Link className='mobile__menu-link' to='/'>Início</Link>
        </li>
        <li className='mobile__menu-item'>
          <Link className='mobile__menu-link' to='/sobre-nos'>Quem Somos</Link>
        </li>
        <li className='mobile__menu-item'>
          <Link className='mobile__menu-link' to='/produtos'>Produtos</Link>
        </li>
        <li className='mobile__menu-item'>
          <Link className='mobile__menu-link' to='/contactos'>Contactos</Link>
        </li>
      </ul>}
    </nav>
  )
}

export default Navigation
