import React from 'react';

import logo from './sotripas.png';
import './Header.scss';

function Header() {
    return (
        <header className="header">
            <img src={ logo } alt="Logo Só tripas" />
            <div className="header__punch-line">
            Call me! Maybe? 917 560 663
            </div>
        </header>
    )
}

export default Header
