import './App.scss';

import Container from './Components/Container/Container';

function App() {
  return (
    <div className="App">
        <Container />
    </div>
  );
}

export default App;
