import React from 'react';

import dgav from '../../assets/Images/dgav.jpeg'
import pontoVerde from '../../assets/Images/pontoverde.png'

import './Footer.scss';

function Footer() {
  return (
    <footer className='footer'>
      <div className="footer__title">
        Parceiros
      </div>
      <div className="footer__parceiros">
        <img src={dgav} alt="Parceiro DGAV" />
        <img src={pontoVerde} alt="Parceiro ponto verde" />
      </div>
    </footer>
  )
}

export default Footer;